import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import ULAILogo from "../Assets/ULAILogo.svg";
import ULAIblackLogo from "../Assets/ULAIbalckLogo.svg";
import { ExpandMore, KeyboardArrowDown, CloseOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { gradientTextStyle } from "../style/gardientText";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

const headerTabs = ["Home", "Features",  "Pricing"];//"Resources",

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  overflow: "hidden",
}));

function FixedHeader({
  theme,
  activeTab,
  setActiveTab,
  calendlyOpen,
  setCalendlyOpen,
  handleRef,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1150);
 const {pathname}=useLocation()
 
  const [expandedMobile, setExpandedMobile] = React.useState({
    features: false,
    resources: false,
  });
  const [dropdownlist, setDropdownlist] = React.useState([]);

  const handleOpenNavMenu = React.useCallback((event) => {
    setAnchorElNav(event.currentTarget);
  }, []);

  const handleCloseNavMenu = React.useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const handleDropDownClose = React.useCallback((e, value, id) => {
    if (value !== "backdropClick") handleRef(id);
    if (id === "#case-studies") setActiveTab("caseStudies");
    setAnchorEl(null);
    setOpenDropdown(false);
  }, [handleRef, setActiveTab]);

  const handleNavButtononClick = React.useCallback((event, page,isMobile) => {
   
    if (page === "Home" || page === "Pricing") setActiveTab(page);
    else if (page === "Features") {
         if (!isMobile)setOpenDropdown(page)
      setDropdownlist([
        { id: "#engagement", displayName: "Engagement" },
        { id: "#ecom-journey", displayName: "E-com Journey" },
        { id: "#whatsapp-channel", displayName: "WhatsApp Channel" },
        { id: "#comprehensive-analytics", displayName: "Comprehensive Analytics" },
        { id: "#code-integration", displayName: "Low Code" },
        { id: "#integrations", displayName: "Integrations" },
        { id: "#ULai-benefits", displayName: "ULai Benefits" },
        { id: "#trusted-brands", displayName: "Trusted by Brands" },
          { id: "#supported-by", displayName: "Supported By" },
{     id: "#schedule-demo", displayName: "Schedule a Demo" }
      ]);
      if (window.innerWidth <= 900) { // Adjust if necessary for mobile
        setExpandedMobile((prev) => ({ ...prev, features: !prev.features }));
      } else {
        setAnchorEl(event.currentTarget);
      }
    } else if (page === "Resources") {
      if (!isMobile)setOpenDropdown(page)
      setDropdownlist([
        { id: "#customer-stories", displayName: "Customer Stories" },
        { id: "#case-studies", displayName: "Case Studies" },
        { id: "#appreciation", displayName: "Appreciation" },
        { id: "#blogs", displayName: "Blogs" },
      ]);
      if (window.innerWidth <= 900) { // Adjust if necessary for mobile
        setExpandedMobile((prev) => ({ ...prev, resources: !prev.resources }));
      } else {
        setAnchorEl(event.currentTarget);
      }
    }
  }, [setActiveTab]);


  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1150);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [])

  return (
    <StyledAppBar
      sx={{
        backgroundColor: activeTab === "caseStudies" ? "white" : "rgba(27, 27, 27, .26)",
        backdropFilter: "blur(21px)",
        boxShadow: "none",
        padding: theme.typography.pxToRem(2),
   
      }}
      position="sticky"
    
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "red",
              textDecoration: "none",
              mr: 8, // Margin right for spacing between Home button and logo
            }}
           
          >
            <img
              src={activeTab === "caseStudies" ? ULAIblackLogo : ULAILogo}
              alt="Logo"
              style={{ width: '100px', height: 'auto' }}
              onClick={(e) => {
                handleNavButtononClick(e, "Home",true);
                navigate("/")}}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                backgroundColor: "primary.main",
                opacity: "0.98",
                display: { xs: "block", md: "none" },
                "& .MuiMenu-paper": {
                  width: "100%",
                  backgroundColor: "primary.main",
                },
              }}
            >
              <Box
                sx={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 2,
                }}
              >
                <img src={ULAILogo} alt="Logo" style={{ width: '100px', height: 'auto' }} />
                <CloseOutlined
                  sx={{ height: 40, width: 50 }}
                  onClick={handleCloseNavMenu}
                />
              </Box>
              <hr style={{ margin: "30px 0", borderColor: "white" }} />
              <MenuItem sx={{ color: "white" }} onClick={(e) => {
                handleNavButtononClick(e, "Home",true);
                navigate("/");
                handleCloseNavMenu()
              }}>
                Home
              </MenuItem>
              <MenuItem
                sx={{ color: "white", display: "flex", justifyContent: "space-between" }}
                onClick={(e) => handleNavButtononClick(e, "Features",true)}
              >
                Features
                <KeyboardArrowDown sx={{ color: "white" }} />
              </MenuItem>
              {expandedMobile.features && dropdownlist.map((i) => (
                <MenuItem
                  key={i.id}
                  sx={{ color: "white" }}
                  onClick={(e) => {handleCloseNavMenu()
                    if(pathname=="/pricing"||pathname=="/data-policy"||pathname=="/privacy-policy"||pathname=="/terms-condition"){
                      handleNavButtononClick(e, "Home",true);
                      navigate("/", { state: i.id});
                    }
               
                    handleDropDownClose(e, null, i.id)}}
                >
                 <Typography     >{i.displayName}</Typography> 
                </MenuItem>
              ))}
              {/* <MenuItem
                sx={{ color: "white", display: "flex", justifyContent: "space-between" }}
                onClick={(e) => handleNavButtononClick(e, "Resources",true)}
              >
                Resources
                <KeyboardArrowDown sx={{ color: "white" }} />
              </MenuItem> */}
              {expandedMobile.resources && dropdownlist.map((i) => (
                <MenuItem
                  key={i.id}
                  sx={{ color: "white" }}
                  onClick={(e) => handleDropDownClose(e, null, i.id)}
                >
                   <Typography     onClick={handleCloseNavMenu}>{i.displayName}</Typography> 
                </MenuItem>
              ))}
              <MenuItem sx={{ color: "white" }} onClick={(e) => {
                navigate("/pricing")
                handleCloseNavMenu()
                handleNavButtononClick(e, "Pricing",true)}}>
                Pricing
              </MenuItem>
              <Box sx={{ px: 2, py: 1 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disableRipple
                  sx={{
                    width: "140px", // Adjust width for mobile
                    color: "white",
                    borderRadius: (theme) => theme.typography.pxToRem(28),
                    padding: (theme) => `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
                    textTransform: "none",
                    fontWeight: "bold",
                    backgroundColor: "primary.button1",
                    "&:hover": {
                      backgroundColor: "primary.button1",
                      borderColor: "primary.button1",
                      boxShadow: (theme) => `0 0 ${theme.typography.pxToRem(6)} #fff`,
                    },
                    mr: 1, // Margin right for spacing between buttons
                  }}
                  onClick={() => setCalendlyOpen(!calendlyOpen)}
                >
                  Book Demo
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disableRipple
                  sx={{
                    width: "140px", // Adjust width for mobile
                    color: "white",
                    textTransform: "none",
                    borderRadius: (theme) => theme.typography.pxToRem(28),
                    padding: (theme) => `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
                    fontWeight: "bold",
                    backgroundColor: "primary.button2",
                    "&:hover": {
                      backgroundColor: "primary.button2",
                      borderColor: "primary.button2",
                      boxShadow: (theme) => `0 0 ${theme.typography.pxToRem(6)} #fff`,
                    },
                    mr: 1, // Margin right for spacing between buttons
                  }}
                  onClick={() => window.open("https://app.ulai.in/")}
                >
                  Try For Free
                </Button>
              </Box>
              <Typography sx={{ color: "white", px: 2 }}>
                No credit cards. 30-day money back guarantee, No strings attached
              </Typography>
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              mr: "34px", // Margin right to add space between Home button and logo
            }}
          >
            <img
              src={ULAILogo}
              alt="Logo"
              style={{ width: '100px', height: 'auto' }}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {headerTabs.map((page) => (
              <Button
                key={page}
                onClick={(e) =>{ 
                     if (page==="Pricing")navigate("/pricing")
                      else if(page==="Home") navigate("/")
                      
                  handleNavButtononClick(e, page,false)}}
                disableRipple
                endIcon={
                  (page === "Features" || page === "Resources") && (
                    <KeyboardArrowDown onClick={() => setOpenDropdown(page)} />
                  )
                }
                sx={{
                  my: 2,
               fontSize: isMobile?theme.typography.pxToRem(14):theme.typography.pxToRem(18), // Adjust font size for better fit
                  color: activeTab === "caseStudies" ? "black" : "white",
                  textTransform: "none",
                  pr: isMobile?2:6,
                  ...gradientTextStyle,
                  display: "flex",
                  alignItems: "center",
                  "&:hover": {
                    textShadow: `0 0 ${theme.typography.pxToRem(6)} #fff`,
                  },
                  mr: 1, // Margin right for spacing between buttons
                }}
                
              >
                {page}
              </Button>
            ))}
          </Box>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDropdown}
            onClose={() => setOpenDropdown(false)}
            MenuListProps={{
              "aria-labelledby": "basic-button",

            }}
            sx={{
              "& .MuiMenu-paper": {
                backgroundColor: "primary.main",
                
              },
            
            }}
          >
            {dropdownlist.map((i) => (
              <MenuItem
                key={i.id}
                sx={{ color: "white" }}
                onClick={(e) => {
                  if(pathname=="/pricing"||pathname=="/data-policy"||pathname=="/privacy-policy"||pathname=="/terms-condition"){
                    handleNavButtononClick(e, "Home",true);
                    navigate("/", { state: i.id});
                  }
             
                  handleDropDownClose(e, null, i.id)}}
              >
                {i.displayName}
              </MenuItem>
            ))}
          </Menu>

          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-between",
            }}
          >
          <Box sx={{ width: 'auto' }}>
              <Button
                variant="outlined"
                color="primary"
                disableRipple
                sx={{
                  width: isMobile?"auto":"140px", // Adjust width for mobile
                  color: "black",
                  borderRadius: (theme) => theme.typography.pxToRem(28),
                  padding: (theme) => `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "white",
                  fontSize: isMobile?theme.typography.pxToRem(14):theme.typography.pxToRem(18),
                  pl:isMobile?4:8,
                  pr:isMobile?4:8,
                  "&:hover": {
                    backgroundColor: "white",
                    borderColor: "primary.button1",
                    boxShadow: `0 0 ${theme.typography.pxToRem(6)} #fff`,
                  },
                  mr: 2, // Margin right for spacing between buttons
                }}
                onClick={() => window.open("https://app.ulai.in")}
              >
                Login
              </Button>
            </Box>
            <Box sx={{ width: 'auto' }}>
              <Button
                variant="outlined"
                color="primary"
                disableRipple
                sx={{
                  width: isMobile?"auto":"140px",  // Adjust width for mobile
                  color: "white",
                  borderRadius: (theme) => theme.typography.pxToRem(28),
                  padding: (theme) => `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "primary.button1",
                  fontSize: isMobile?theme.typography.pxToRem(14):theme.typography.pxToRem(18),
                  "&:hover": {
                    backgroundColor: "primary.button1",
                    borderColor: "primary.button1",
                    boxShadow: `0 0 ${theme.typography.pxToRem(6)} #fff`,
                  },
                  mr: 2, // Margin right for spacing between buttons
                }}
                onClick={() => setCalendlyOpen(!calendlyOpen)}
              >
                Book Demo
              </Button>
            </Box>
            <Box sx={{ width: 'auto' }}>
              <Button
                variant="outlined"
                color="primary"
                disableRipple
                sx={{
                  width: isMobile?"auto":"140px", // Adjust width for mobile
                  color: "white",
                  borderRadius: (theme) => theme.typography.pxToRem(28),
                  padding: (theme) => `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "primary.button2",
                  fontSize: isMobile?theme.typography.pxToRem(14):theme.typography.pxToRem(18),
                  "&:hover": {
                    backgroundColor: "primary.button2",
                    borderColor: "primary.button2",
                    boxShadow: `0 0 ${theme.typography.pxToRem(6)} #fff`,
                  },
                }}
                onClick={() => window.open("https://app.ulai.in")}
              >
                Try For Free
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}

export default FixedHeader;


