import * as React from "react";
import { Grid, Box, Typography, Stack } from "@mui/material";
import { AccessAlarm, Computer } from "@mui/icons-material";
import { InlineWidget } from "react-calendly";
import { gradientTextStyle } from "../style/gardientText";

function ScheduleADemo({ theme,refMap }) {
  const ref=React.useRef()
  return (
    
            <Stack sx={{width:"100%",alignItems:"center", mb:"60px"}} ref={refMap}>
            <Stack sx={{width:{sm:"100%",md:"70%"}}}>
      <Grid
        container
        spacing={{xs:2,md:4}}
        sx={{
          mt: { xs: 5, md: 10 }, // Adjust top margin for different screen sizes
          px: { xs: 2, md: 0 }, // Adjust padding horizontally for small screens
          width: "100%", // Ensure grid takes full width
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-end" }, // Center items on small screens, align to end on larger screens
            width: { xs: "100%", md: "70%" }, // Full width on small screens, 70% on larger screens
            padding: { xs: 0, md: 0 }, // Padding adjustments
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 5,
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
             
            }}
          >
            <Typography sx={{ fontSize:{ xs: theme.typography.pxToRem(30), md: theme.typography.pxToRem(50) }, ...gradientTextStyle, textAlign: "center" }}>
              Schedule a Demo
            </Typography>
            <Typography sx={{ fontSize: { xs: theme.typography.pxToRem(20), md: theme.typography.pxToRem(20) }, textAlign: "center" ,color:"white"}}>
              Let's explore Ulai together, and you'll be convinced why it's the right platform for you.
            </Typography>
            <div style={{ height: "2px", background: "grey", width: "100%" }}></div>
            <Box
              sx={{
                width: "100%",
                padding: `${theme.typography.pxToRem(30)} 0`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Grid container spacing={2} sx={{ flexWrap: "nowrap", justifyContent: "center" ,mb:2}}>
                <Grid item xs={4} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <AccessAlarm
                    sx={{
                      height: theme.typography.pxToRem(50),
                      width: theme.typography.pxToRem(50),
                      color: 'white',
                    }}
               
                  />
                  <Box>
                    <Typography sx={{ fontSize: { xs: theme.typography.pxToRem(16), md: theme.typography.pxToRem(20) }, ...gradientTextStyle }}>TIME</Typography>
                    <Typography sx={{ fontSize:  { xs: theme.typography.pxToRem(16), md: theme.typography.pxToRem(20) }, ...gradientTextStyle }}>30 mins</Typography>
                  </Box>
                </Grid>
               
              </Grid>
              {/* <Typography sx={{ fontSize:  { xs: theme.typography.pxToRem(16), md: theme.typography.pxToRem(20) }, textAlign: "center" }}>
                A step-by-step guide to make you familiar with Ulai's conversation commerce platform
              </Typography> */}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", md: "70%" }, // Full width on small screens, 70% on larger screens
          }}
        >
          <Box
            sx={{
              p: { xs: 2, md: 0 }, // Padding adjustments
              color: "white",
              display: "flex",
              flexDirection: "column",
              height: { xs: "140%", md: theme.typography.pxToRem(420) }, // Adjust height for responsiveness
              
              width: "100%",
              justifyContent: "center",
            }}
          >
            <InlineWidget
              styles={{ height: "100%" }}
              hideLandingPageDetails
              url="https://tidycal.com/ulai/power-of-ulai"
            />
          </Box>
        </Grid>
      </Grid>
    </Stack>
    </Stack>
  );
}

export default ScheduleADemo;
