import React, { useEffect, useRef } from "react";
import { Container, useMediaQuery } from "@mui/material";
import FixedHeader from "Component/FixedHeader";
import TopGridInfoBanner from "Component/TopGridInfoBanner";
import EngageNewUserInfo from "Component/EngageNewUserInfo";
import EndtoEndShopingExperience from "Component/EndtoEndShopingExperience";
import TwoWayWhatsappMarketing from "Component/TwoWayWhatsappMarketing";
import AmazingSupport from "Component/AmazingSupport";
import BottomFooter from "Component/BottomFooter";
import ComprehensiveAnalytics from "Component/ComprehensiveAnalytics";
import SeamlessIntegration from "Component/SeamlessIntegration";
import { useTheme } from "@emotion/react";
import ScheduleADemo from "Component/ScheduleADemo";
import InfiniteCrousel from "Component/InfiniteCrousel";
import PricingPage from "Component/PricingPage";
import TermsOfService from "Component/TermsOfService";
import DataPolicy from "Component/DataPolicy";
import PrivacyPolicy from "Component/PrivacyPolicy";
import CaseStudies from "Component/CaseStudies";
import axios from "axios";
import { PopupModal } from "react-calendly";
import AnimatedSection from "./AnimatedSection";
import ResponsiveTable from "./Component/TableUlai";
import TimerIntegration from "./Component/TimerIntegration";

import "./Home.css";
import TopBannerImg from "./Component/TopBannerImg";
import Support from "./Component/Supported";
import { useLocation, useNavigate } from "react-router-dom";

// Animation Variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const slideInLeft = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0 },
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const scrollToSection = (id, refs, offset = 80) => {
  let targetRef = null;

  // Match the id to the corresponding ref
  switch (id) {
    case "#engagement":
      targetRef = refs.engagementRef;
      break;
    case "#ecom-journey":
      targetRef = refs.ecomJourneyRef;
      break;
    case "#whatsapp-channel":
      targetRef = refs.whatsappChannelRef;
      break;
    case "#comprehensive-analytics":
      targetRef = refs.comprehensiveAnalyticsRef;
      break;
    case "#code-integration":
      targetRef = refs.codeIntegrationRef;
      break;
    case "#integrations":
      targetRef = refs.integrationsRef;
      break;
    case "#ULai-benefits":
      targetRef = refs.ulaiBenefitsRef;
      break;
    case "#trusted-brands":
      targetRef = refs.trustedBrandsRef;
      break;
    case "#supported-by":
      targetRef = refs.supportedByRef;
      break;
    case "#schedule-demo":
      targetRef = refs.scheduleDemoRef;
      break;
    default:
      return;
  }

  // Scroll to the target ref if it exists
  if (targetRef && targetRef.current) {
    const element = targetRef.current;
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: elementTop - offset,
      behavior: "smooth"
    });
  }
};

function Homepage({
  activeTab,
  setActiveTab,
  appreciationRef,
  customerStoriesRef,
  engagementRef,
  ecomJourneyRef,
  whatsappChannelRef,
  comprehensiveAnalyticsRef,
  integrationsRef,
  calendlyOpen, 
  setCalendlyOpen,
  codeIntegrationRef,
  ulaiBenefitsRef,
  trustedBrandsRef,
  supportedByRef,
  scheduleDemoRef
}) {
  const refs = {
    engagementRef,
    ecomJourneyRef,
    whatsappChannelRef,
    comprehensiveAnalyticsRef,
    codeIntegrationRef,
    integrationsRef,
    ulaiBenefitsRef,
    trustedBrandsRef,
    supportedByRef,
    scheduleDemoRef
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is mobile
  // const [activeTab, setActiveTab] = React.useState("Home");
  // const [calendlyOpen, setCalendlyOpen] = React.useState(false);
 
  const elementRef = useRef(null);
  const [planFeaturesList, setPlanFeaturesList] = React.useState();
  const [image1Show, setImage1Show] = React.useState(false);
  const {state}=useLocation()
  const navigate = useNavigate();
  // useEffect(()=>{
  // if(state){
  //   handl
  // }
  // },[])

  const [showLanding3, setShowLanding3] = React.useState(false);
  const [plans, setPlans] = React.useState([
    {
      id: 1,
      title: "fixed Hidden section",
      planFeatures: [],
    },
  ]);

  const handleTouch = () => {
    if (image1Show) {
      setShowLanding3(!showLanding3); // Toggle between landing2 and landing3
    } else {
      setImage1Show(true);
    }
  };
  async function getPlans() {
    const resp = await axios.get("https://app.ulai.in/api/admin/get_plans", {
      headers: {},
    });
    if (resp?.data?.success && resp?.data?.data?.length > 0) {
      setPlans(
        resp.data.data.map((itm, index) => {
          setPlanFeaturesList(itm.planFeatures ?? []);
          return {
            id: index + 1,
            title: itm.title,
            buttonText: itm.price ? "Get In Touch" : "Try For Free",
            link: itm.price ? "" : "https://app.ulai.in",
            description: itm.price
              ? "Perfect for emerging businesses that are looking for powerful features to automate sales and increase their topline."
              : "This plan offers a limited-time free trial of our product, allowing you to test out its features before committing to a paid plan.",
            subTitle: null,
            planFeatures: itm.planFeatures,
          };
        })
      );
    }
  }

  useEffect(() => {
    getPlans();
  }, []);

  // Scroll position reset
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to top on page load
  }, [activeTab]);

  // const engagementRef = React.useRef(null);
  // const ecomJourneyRef = React.useRef(null);
  // const whatsappChannelRef = React.useRef(null);
  // const comprehensiveAnalyticsRef = React.useRef(null);
  // const integrationsRef = React.useRef(null);
  // const customerStoriesRef = React.useRef(null);
  // const appreciationRef = React.useRef(null);

  // const handleRef = (id) => {
  //   if (id === "#engagement")
  //     engagementRef.current?.scrollIntoView({ behavior: "smooth" });
  //   else if (id === "#ecom-journey")
  //     ecomJourneyRef.current?.scrollIntoView({ behavior: "smooth" });
  //   else if (id === "#whatsapp-channel")
  //     whatsappChannelRef.current?.scrollIntoView({ behavior: "smooth" });
  //   else if (id === "#comprehensive-analytics")
  //     comprehensiveAnalyticsRef.current?.scrollIntoView({ behavior: "smooth" });
  //   else if (id === "#integrations")
  //     integrationsRef.current?.scrollIntoView({ behavior: "smooth" });
  //   else if (id === "#customer-stories")
  //     customerStoriesRef.current?.scrollIntoView({ behavior: "smooth" });
  //   else if (id === "#appreciation")
  //     appreciationRef.current?.scrollIntoView({ behavior: "smooth" });
  // };
  useEffect(() => {
    if (state && typeof state === "string") {
      scrollToSection(state, refs);
      navigate('/')
    }
  }, [state]);
  return (
    <>
   
      <Container ref={elementRef} className="snap-container" sx={{ mt: 0}} maxWidth="false" disableGutters >
        {activeTab === "Home" && (
          <>
            {isMobile ? (
              <>
                <TopGridInfoBanner theme={theme} />
                <TopBannerImg theme={theme} setImage1Show={setImage1Show } image1Show={image1Show} setShowLanding3={setShowLanding3} showLanding3={showLanding3} handleTouch={handleTouch}  />
                <EngageNewUserInfo refMap={engagementRef} theme={theme} handleTouch={handleTouch} />
                 <EndtoEndShopingExperience
                  refMap={ecomJourneyRef}
                  theme={theme}
                  calendlyOpen={calendlyOpen}
                  setCalendlyOpen={setCalendlyOpen}
                 
                />
               <TwoWayWhatsappMarketing refMap={whatsappChannelRef} theme={theme} />
              <ComprehensiveAnalytics
                  refMap={comprehensiveAnalyticsRef}
                  theme={theme}
                  calendlyOpen={calendlyOpen}
                  setCalendlyOpen={setCalendlyOpen}
                />
                <TimerIntegration
                   refMap={codeIntegrationRef}
                  theme={theme}
                  calendlyOpen={calendlyOpen}
                  setCalendlyOpen={setCalendlyOpen}
                />
                  <SeamlessIntegration
                  refMap={integrationsRef}
                  theme={theme}
                  calendlyOpen={calendlyOpen}
                  setCalendlyOpen={setCalendlyOpen}
                />
               <ResponsiveTable
               refMap={ulaiBenefitsRef}
                  theme={theme}
                  calendlyOpen={calendlyOpen}
                  setCalendlyOpen={setCalendlyOpen}
                />
                
                <InfiniteCrousel theme={theme} refMap={trustedBrandsRef} />
                <Support 
                   refMap={supportedByRef}
                    theme={theme}
                  />
                <ScheduleADemo theme={theme} refMap={scheduleDemoRef}       /> 
              </>
            ) : (
              <>
                <AnimatedSection animationVariant={fadeInUp}>
                  <TopGridInfoBanner theme={theme} />
                </AnimatedSection>
                <AnimatedSection animationVariant={slideInLeft}>
                  <TopBannerImg theme={theme}  setImage1Show={setImage1Show } image1Show={image1Show} setShowLanding3={setShowLanding3} showLanding3={showLanding3} handleTouch={handleTouch} />
                </AnimatedSection>
                <AnimatedSection animationVariant={fadeInUp}>
                  <EngageNewUserInfo refMap={engagementRef} theme={theme}   handleTouch={handleTouch} />
                </AnimatedSection>
                <AnimatedSection animationVariant={slideInLeft}>
                  <EndtoEndShopingExperience
                    refMap={ecomJourneyRef}
                    theme={theme}
                    calendlyOpen={calendlyOpen}
                    setCalendlyOpen={setCalendlyOpen}
                  />
                </AnimatedSection>
                <AnimatedSection animationVariant={fadeIn}>
                  <TwoWayWhatsappMarketing refMap={whatsappChannelRef} theme={theme} />
                </AnimatedSection>
                <AnimatedSection animationVariant={fadeInUp}>
                  <ComprehensiveAnalytics
                    refMap={comprehensiveAnalyticsRef}
                    theme={theme}
                    calendlyOpen={calendlyOpen}
                    setCalendlyOpen={setCalendlyOpen}
                  />
                </AnimatedSection>
                <AnimatedSection animationVariant={slideInLeft}>
                  <TimerIntegration
                    refMap={codeIntegrationRef}
                    theme={theme}
                    calendlyOpen={calendlyOpen}
                    setCalendlyOpen={setCalendlyOpen}
                  />
                </AnimatedSection>
                <AnimatedSection animationVariant={slideInLeft}>
                  <SeamlessIntegration
                    refMap={integrationsRef}
                    theme={theme}
                    calendlyOpen={calendlyOpen}
                    setCalendlyOpen={setCalendlyOpen}
                  />
                </AnimatedSection>
                <AnimatedSection animationVariant={slideInLeft}>
                  <ResponsiveTable
                 refMap={ulaiBenefitsRef} 
                    theme={theme}
                    calendlyOpen={calendlyOpen}
                    setCalendlyOpen={setCalendlyOpen}
                  />
                </AnimatedSection>
                {/* <AnimatedSection animationVariant={fadeIn}>
                  <AmazingSupport theme={theme} />
                </AnimatedSection> */}
                <AnimatedSection animationVariant={fadeInUp}>
                  <InfiniteCrousel theme={theme}  refMap={trustedBrandsRef} />
                </AnimatedSection>
                <AnimatedSection animationVariant={fadeInUp}>
                  <Support 
                    refMap={supportedByRef} 
                    theme={theme}
                  />
                </AnimatedSection>
                <AnimatedSection animationVariant={fadeInUp} >
                  <ScheduleADemo theme={theme} refMap={scheduleDemoRef}  />
                </AnimatedSection>
              </>
            )}
          </>
        )}
        {/* <div id="bookAdemoButton"></div>
        <PopupModal
          url="https://calendly.com/ulai/30min?hide_landing_page_details=1&hide_gdpr_banner=1'"
          onModalClose={() => setCalendlyOpen(!calendlyOpen)}
          open={calendlyOpen}
          rootElement={document.getElementById("bookAdemoButton")}
          text="Book Demo"
          textColor="#ffffff"
        /> */}
        {/* {activeTab === "Pricing" && (
          <div className="snap-section">
          <PricingPage
            theme={theme}
            plans={plans}
            planFeaturesList={planFeaturesList}
            calendlyOpen={calendlyOpen}
            setCalendlyOpen={setCalendlyOpen}
          />
          </div>
        )} */}
        {activeTab === "caseStudies" && <CaseStudies theme={theme} setActiveTab={setActiveTab} />}
        {/* {activeTab === "dataPolicy" && <DataPolicy theme={theme} />}
        {activeTab === "privacyPolicy" && <PrivacyPolicy theme={theme} />}
        {activeTab === "termAndCondition" && <TermsOfService theme={theme} />} */}
        <div className="snap-section">
          <BottomFooter
            theme={theme}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
              calendlyOpen={calendlyOpen}
                  setCalendlyOpen={setCalendlyOpen}
          />
        </div>
      </Container>
    </>
  );
}

export default Homepage;
